<template>
  <v-slide-x-reverse-transition>
    <v-card
      v-if="model"
      color="red"
      max-width="350"
      class="upload-widget"
      width="100%"
    >
      <v-expansion-panels
        v-model="panelsModel"
        multiple
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            Загрузки
            <template v-slot:actions>
              <v-icon
                size="24"
              >
                mdi-chevron-down
              </v-icon>
              <v-icon
                size="24"
                class="ml-5"
                style="transition: none; translate: none !important;"
                @click.stop="model = false"
              >
                mdi-close
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="custom-scroll pa-0"
            style="max-height: 250px !important; overflow: auto !important;"
          >
            <v-list
              v-if="Object.keys(items).length"
              class="pt-0 pb-5 mx-0 px-0 mb-3"
            >
              <v-list-item
                :ripple="false"
                style="min-height: 45px !important;"
                @mouseenter="() => onMouseEnter(item)"
                @mouseleave="() => onMouseLeave(item)"
                @click="() => openItem(item)"
                v-for="item in items"
                :key="`item-${item.documentId}`"
              >
                <v-list-item-icon
                  class="mr-3"
                >
                  <v-icon>
                    mdi-file
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getName(item) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-btn
                  v-if="item.state === 'canceled'"
                  icon
                >
                  <v-icon
                    color="error"
                  >
                    mdi-information
                  </v-icon>
                </v-btn>
                <v-btn
                  @click.stop="() => cancelItem(item)"
                  v-if="item.prgoress !== 100 && item.state === 'running'"
                  icon
                >
                  <v-icon
                    color="error"
                    v-if="hovered && hovered === item.documentId"
                  >
                    mdi-close-circle
                  </v-icon>
                  <v-progress-circular 
                    v-else
                    size="22"
                    v-model="item.progress"
                    
                    width="2"
                    color="primary"
                  />
                </v-btn>
                <v-list-item-icon
                  class="pt-0 d-flex align-self-center"
                  title="Открыть путь к файлу"
                  v-if="item.progress === 100 && item.state === 'success'"
                >
                  <v-btn
                    icon
                    @click.stop="() => openFolder(item)"
                  >
                    <v-icon
                      v-if="hovered && hovered === item.documentId"
                    >
                      mdi-folder-outline
                    </v-icon>
                    <v-icon
                      v-else
                      color="green"
                    >
                      mdi-check-circle
                    </v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-slide-x-reverse-transition>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  name: 'UploadWidget',
  data: () => ({
    panelsModel: [0],
    hovered: null
  }),
  computed: {
    items() {
      return this.$store.getters.listFilesInUploadProcess
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$store.commit('setUploadModel', val)
      }
    }
  },
  methods: {
    cancelItem(item) {
      item.uploadTask && item.uploadTask.cancel()
      this.$emit('cancelFile', item)
    },
    onMouseEnter(item) {
      this.hovered = item.documentId
    },
    onMouseLeave() {
      this.hovered = null
    },
    openFolder(item) {
      const { folderId = null } = item
      this.$emit('goToFolder', folderId)
    },
    openItem(item) {
      this.$emit('openFile', item)
    },
    getName(item) {
      const { path } = item
      const splited = path.split('/')
      return splited[splited.length - 1]
    }
  }
}
</script>
<style lang="scss">
.upload-widget {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 10001;

  .v-expansion-panel-content__wrap {
    padding: 0px;
  }
}
</style>